import React from "react"
import styled from "@emotion/styled"
import BoxedLayout from "../components/layouts/BoxedLayout"
import Seo from "../components/seo"

const GridCont = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
`
const GridElement = styled.div`
  margin: 5px;
  border: 1px solid black;
  border-radius: 5px;
`
const IndexPage = data => {
  return (
    <BoxedLayout>
      <Seo title="Grid Example" />
      <GridCont>
        <GridElement>1</GridElement>
        <GridElement>2</GridElement>
        <GridElement>3</GridElement>
        <GridElement>4</GridElement>
        <GridElement>5</GridElement>
        <GridElement>6</GridElement>
        <GridElement>7</GridElement>
        <GridElement>8</GridElement>
        <GridElement>9</GridElement>
        <GridElement>10</GridElement>
      </GridCont>
    </BoxedLayout>
  );
}

export default IndexPage
